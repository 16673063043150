import { createContext, useContext, useEffect, useState } from 'react';
import {
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    signOut,
    onAuthStateChanged,
    updateProfile,
    sendPasswordResetEmail,
    sendEmailVerification,
    GoogleAuthProvider,
    signInWithRedirect,
    getAdditionalUserInfo,
    FacebookAuthProvider,
    getRedirectResult,
    signInWithPopup,

    
  } from 'firebase/auth';
  import { auth, db, storage } from '../firebase';
  import { setDoc, doc, getDoc, addDoc } from 'firebase/firestore';
  import { getDownloadURL, ref } from "firebase/storage";
  import { useNavigate } from 'react-router-dom';
  import { message } from 'antd';
import Alert from 'antd/es/alert/Alert';

const UserContext = createContext();

export const AuthContextProvider = ({ children }) => {
    const [user, setUser] = useState({});
    const [highlevelError, setHighlevelError] = useState('');
    const [success, setSuccess] = useState('');
    const [message, setMessage] = useState('');
    const [currentUser, setCurrentUser] = useState({});
    const [userNumber, setUserNumber] = useState('');

    const navigate = useNavigate();

    const HandleSignup = async (firstName, lastName, email, password, number, about, photoURL) => {
        try {
            const { user } = await createUserWithEmailAndPassword(auth, email, password);
            await updateProfile(auth.currentUser, { displayName: firstName + ' ' + lastName, photoURL: photoURL, number: number });
            await setDoc(doc(db, 'users', user.uid), {
                firstName: firstName,
                lastName: lastName,
                email: email,
                number: number,
                about: about,
                photoURL: photoURL,
                userPassword: password,
                uid: user.uid,
                dataCreated: user.metadata.creationTime,
            });
            await sendEmailVerification(user);
            navigate('/');
            setUser(user);
            setSuccess('Account created successfully. Please verify your email.');
        } catch (error) {
            setHighlevelError(error.message);
        }
    }

    const HandleGoogleSignup = async () => {
        try {
            const provider = new GoogleAuthProvider();
            await signInWithRedirect(auth, provider);
            const result =  getAdditionalUserInfo(auth);
            if (result === null) {
                console.log('not found');
            }

            const { user } = result;
            alert('user Is: ' + user);
            await setDoc(doc(db, 'users', user.uid), {
                firstName: user.displayName.split(' ')[0],
                lastName: user.displayName.split(' ')[1],
                email: user.email,
                number: user.phoneNumber,
                about: '',
                photoURL: user.photoURL,
                uid: user.uid,
                dataCreated: user.metadata.creationTime,
            });
            setUser(user);
            navigate('/');

        } catch (error) {
            setHighlevelError(error.message);
        }

    }


    

    


    const HandleFacebookSignup = async () => {
        try {
            const provider = new FacebookAuthProvider();
            await signInWithRedirect(auth, provider);
            const result = await getAdditionalUserInfo(auth);
            const { user } = result;
            await setDoc(doc(db, 'users', user.uid), {
                firstName: user.displayName.split(' ')[0],
                lastName: user.displayName.split(' ')[1],
                email: user.email,
                number: user.phoneNumber,
                about: '',
                photoURL: user.photoURL,
                uid: user.uid,
                dataCreated: user.metadata.creationTime,
            });
            setUser(user);
            navigate('/');
        } catch (error) {
            setHighlevelError(error.message);
        }
    }
    

    const HandleLogin = async (email, password) => {
        try {
            const { user } = await signInWithEmailAndPassword(auth, email, password);
            setUser(user);
            
            navigate('/');
        } catch (error) {
            setHighlevelError(error.message);
            navigate('/login');
        }
    }

    const HandleLogout = async () => {
        try {
            await signOut(auth);
            setUser({});
            navigate('/');
        } catch (error) {
            setHighlevelError(error.message);
        }
    }

    const handleResetPassword = async (email) => {
        try {
            await sendPasswordResetEmail(auth, email);
            setSuccess('Email-ka aad gelisay waxaa loo dirayaa faahfaahin dheeraad ah')
            setHighlevelError('');
        } catch (error) {
            setHighlevelError(error.message);
            setSuccess('');
        }
    }

    const handleUpdateProfile = async (firstName, lastName, number, about, photoURL) => {
        try {
            const { user } = await updateProfile(auth.currentUser, { displayName: firstName + ' ' + lastName, photoURL: photoURL });
            await setDoc(doc(db, 'users', user.uid), {
                firstName: firstName,
                lastName: lastName,
                number: number,
                about: about,
                photoURL: photoURL,
                uid: user.uid,
                dataCreated: user.metadata.creationTime,
            });
            setUser(user);
            setSuccess('Profile updated successfully.');
        } catch (error) {
            setHighlevelError(error.message);
            Alert(error.message);
        }
    }

    const handleUpdatePassword = async (password) => {
        try {
            await auth.currentUser.updatePassword(password);
            setSuccess('Password updated successfully.');
        } catch (error) {
            setHighlevelError(error.message);
        }
    }

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            setUser(currentUser);
            console.log(currentUser);
        }
        );
        return unsubscribe;
    }
        , []);


    return (
        <UserContext.Provider value={{ 
            user, 
            highlevelError,
            success, 
            HandleSignup, 
            HandleLogin, 
            HandleLogout, 
            handleResetPassword, 
            handleUpdateProfile, 
            handleUpdatePassword,
            HandleGoogleSignup,
            HandleFacebookSignup,
            }}>
            {children}
        </UserContext.Provider>
    );

}

export const useAuth = () => {
    return useContext(UserContext);
}