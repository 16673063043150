import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useAuth } from '../context/AuthContext';
import { message, Progress, Alert } from "antd";
import Logo from '../assets/SuuqIibiye.png'



const Login = () => {

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')


    const [UserError, setUserError] = useState('')
    const [UserSuccess, setUserSuccess] = useState('')

    const { HandleLogin, error = '', handleResetPassword, success, highlevelError } = useAuth();
    const [forgotPasswordMode, setForgotPasswordMode] = useState(false)

    const navigate = useNavigate()

    const handlePasswordReset = async (e) => {
        setUserError('')
        e.preventDefault()
        try {
            await handleResetPassword(email)
            // setUserSuccess('Email-ka aad gelisay waxaa loo dirayaa faahfaahin dheeraad ah')
            setForgotPasswordMode(false)
        } catch (error) {
            // setUserError("Email-ka aad gelisay maaha mid jira")
            alert(error)
        }
    }

    const handleSubmit = async (e) => {
        setUserError('')
        setUserError('')
        e.preventDefault()
        if (email === '' || password === '') {
             setUserError('Fadlan buuxi foomka')
             return
        } else {
        
        try {
            await HandleLogin(email, password)
            setUserError('')
        } catch (error) {
            setUserError(error.message)
        }

        }
    }

    return (
        <div className=" flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
            <div className="sm:mx-auto  sm:w-full sm:max-w-sm">
                <img
                    className="mx-auto h-20 w-auto"
                    src={Logo}
                    alt="Suuq Iibiye"
                />
                <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                   
                    Gali Email-kaaga iyo Furaha Sirta ah
                </h2>
            </div>

            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                <form className="space-y-6" action="#" method="POST">
                <input type="hidden" name="remember" defaultValue="true" />
                    {/* <div className="mt-6 grid grid-cols-1 gap-3">

                        <div>
                            <button type="button" className="text-black bg-gray-100 hover:bg-gray-300 focus:ring-4 focus:outline-none focus:ring-[#4285F4]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-[#4285F4]/55 me-2 mb-2 w-full">
                                <svg class="w-4 h-4 me-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 19">
                                    <path fill-rule="evenodd" d="M8.842 18.083a8.8 8.8 0 0 1-8.65-8.948 8.841 8.841 0 0 1 8.8-8.652h.153a8.464 8.464 0 0 1 5.7 2.257l-2.193 2.038A5.27 5.27 0 0 0 9.09 3.4a5.882 5.882 0 0 0-.2 11.76h.124a5.091 5.091 0 0 0 5.248-4.057L14.3 11H9V8h8.34c.066.543.095 1.09.088 1.636-.086 5.053-3.463 8.449-8.4 8.449l-.186-.002Z" clip-rule="evenodd" />
                                </svg>
                                Sign in with Google
                            </button>

                        </div>
                        <div>
                            <button type="button" className="text-white bg-[#4285F4] hover:bg-[#4285F4]/90 focus:ring-4 focus:outline-none focus:ring-[#4285F4]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-[#4285F4]/55 me-2 mb-2 w-full">
                                <svg class="w-4 h-4 me-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 8 19">
                                    <path fill-rule="evenodd" d="M6.135 3H8V0H6.135a4.147 4.147 0 0 0-4.142 4.142V6H0v3h2v9.938h3V9h2.021l.592-3H5V3.591A.6.6 0 0 1 5.592 3h.543Z" clip-rule="evenodd" />
                                </svg>
                                Sign in with Facebook
                            </button>

                        </div>

                    </div>
                    <div className="mt-6">
                        <div className="relative">
                            <div className="absolute inset-0 flex items-center">
                                <div className="w-full border-t border-gray-300" />
                            </div>
                            <div className="relative flex justify-center text-sm">
                                <span className="px-2 bg-white text-gray-500">Or continue with</span>
                            </div>
                        </div>


                    </div> */}
                    <div>
                        <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                            Email-kaaga
                        </label>
                        <div className="mt-2">
                            <input
                                id="email"
                                name="email"
                                type="email"
                                autoComplete="email"
                                required
                                placeholder='hebel@gmail.com'
                                onChange={(e) => setEmail(e.target.value)}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                            />
                        </div>
                    </div>

                    {forgotPasswordMode ? (
                        <div className="flex items-end justify-end">
                            
                        
                        <div className="text-sm">
                            <a onClick={() => setForgotPasswordMode(false)}
                            className="font-semibold text-green-600 hover:text-green-500">
                                Xasuustay Furahii sirta aha?
                            </a>
                        </div>
                    </div>

                    ) : (
                    <div>
                        <div className="flex items-center justify-between">
                            <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                                Furaha Sirta ah
                            </label>
                            <div className="text-sm">
                                <a onClick={() => setForgotPasswordMode(true)}
                                className="font-semibold text-green-600 hover:text-green-500">
                                    Ilaabay Furahii sirta aha?
                                </a>
                            </div>
                        </div>
                        <div className="mt-2">
                            <input
                                id="password"
                                name="password"
                                type="password"
                                autoComplete="current-password"
                                required
                                placeholder='********'
                                onChange={(e) => setPassword(e.target.value)}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                            />
                        </div>
                    </div>
                    )}

                    <div>
                        
                        <button
                            type="submit"
                            onClick={forgotPasswordMode ? handlePasswordReset : handleSubmit}
                            
                            className="flex w-full justify-center rounded-md bg-green-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                        >
                          {forgotPasswordMode ? 'Isoo dir Furaha Sirta ah' : 'Gal Akoon-kaygii'}
                        </button>
                    </div>

                    {success && <Alert message={success} type="success" showIcon />}


                    {UserError && <Alert message={UserError} type="error" showIcon />}
                    {error && <Alert message={error} type="error" showIcon />}
                    {/* {highlevelError && <Alert message={highlevelError} type="error" showIcon />} */}
                
                    {highlevelError && highlevelError === 'Firebase: Error (auth/wrong-password).' && <Alert message='Furaha aad gelisay waa khalad. Fadlan taabo Ilaabay Furahii sirta aha?' type="error" showIcon />}
                    {highlevelError && highlevelError === 'Firebase: Error (auth/user-not-found).' && <Alert message='Email-ka aad gelisay maaha mid jira. Fadlan taabo Akoon-cusub samee' type="error" showIcon />}
                    {highlevelError && highlevelError === 'Firebase: Error (auth/too-many-requests).' && <Alert message='Waxaa jira xaalad kale. Fadlan iska hubi' type="error" showIcon />}
                    {highlevelError && highlevelError === 'Firebase: Error (auth/invalid-login-credentials).' && <Alert message='Email-ka ama Furaha Sirta ah waa khalad. Fadlan iska hubi' type="error" showIcon />}
                    {highlevelError && highlevelError === 'Firebase: Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later. (auth/too-many-requests).' && <Alert message='Email-ka aad gelisay waa la xayiray. Fadlan hadhow ku celi' type="error" showIcon />}
                    {highlevelError && highlevelError === 'Firebase: Error (auth/invalid-email).' && <Alert message='Email-ka aad gelisay maaha mid jira. Fadlan iska hubi' type="error" showIcon />}
                </form>

                <p className="mt-10 text-center text-sm text-gray-500">
                    Akoon kuma lihi?{' '}
                    <a href="#" className="font-semibold leading-6 text-green-600 hover:text-green-500">
                        <Link to='/signup'>
                            Samayso Akoon Cusub
                        </Link>
                    </a>
                </p>

                <p className="mt-6 text-center text-sm text-gray-500">
                    Warbixin Qaldan{' '}
                    <a href="#" className="font-semibold leading-6 text-green-600 hover:text-green-500">
                        <Link to='/' >Ku noqo qaybta Alaabaha</Link>
                    </a>
                </p>


            </div>
        </div>
    )
}

export default Login