import React, { useState, useEffect, Fragment, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc, updateDoc, onSnapshot, query, collection, where, increment, deleteDoc } from 'firebase/firestore';
import { db } from '../firebase'; // Import your firebase configuration
import { Link } from 'react-router-dom';
import { Image, Carousel, message, Spin } from 'antd';
import { useAuth } from '../context/AuthContext.js';
import { Dialog, Transition } from '@headlessui/react'
import { ExclamationTriangleIcon, PencilIcon } from '@heroicons/react/24/outline'





const ProductDetails = () => {
  const navigate = useNavigate();
  const [productLoading, setProductLoading] = useState(true);
  const { id } = useParams();
  const [productDetails, setProductDetails] = useState([]);

  // product states
  const [productName, setProductName] = useState("");
  const [productLocation, setProductLocation] = useState("");
  const [productCategory, setProductCategory] = useState("");
  const [productDescription, setProductDescription] = useState("");
  const [productPrice, setProductPrice] = useState("");
  const [productContact, setProductContact] = useState("");

  const [similarProducts, setSimilarProducts] = useState([]);
  const [loadSimilarProducts, setLoadSimilarProducts] = useState(false);
  // fetch similar products from cache
  useEffect(() => {
    const cachedProducts = JSON.parse(localStorage.getItem('products'));
    if (cachedProducts) {
      // filter products to exclude the current product & category is similar to the current product
      const filteredProducts = cachedProducts.filter((product) => product?.id !== id && product?.category === productDetails?.category);
      setSimilarProducts(filteredProducts);
    }
  }

    , [loadSimilarProducts === true]);

  const [editProductModal, setEditProductModal] = useState(false);
  const cancelButtonRef = useRef(null);



  const { user, HandleLogout } = useAuth();


  const handleEditProduct = async (e) => {
    e.preventDefault();
    if (productCategory === "") {
      message.error("Fadlan dooro Nooca Alaabta");
    } else if (productDescription === "") {
      message.error("Fadlan geli Faahfaahinta Alaabta");
    } else if (productPrice === "") {
      message.error("Fadlan geli Qiimaha Alaabta");
    } else if (productContact === "") {
      message.error("Fadlan geli Lambarka Iibiyaha");
    } else if (productLocation === "") {
      message.error("Fadlan dooro Gobolka Alaabta");
    } else {
      try {
        const productRef = doc(db, "products", id);
        await updateDoc(productRef, {
          category: productCategory,
          description: productDescription,
          price: productPrice,
          contact: productContact,
          approved: false,
        });
        message.success("Alaabta waa la badalay");
        window.location.reload();
      } catch (error) {
        message.error("Laguma guulaysan in alaabta la badalo");
      }
    }
  };

  const handleFetchProductForEdit = async () => {
    try {
      const docRef = doc(db, "products", id);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setProductName(docSnap.data().name);
        setProductLocation(docSnap.data().location);
        setProductCategory(docSnap.data().category);
        setProductDescription(docSnap.data().description);
        setProductPrice(docSnap.data().price);
        setProductContact(docSnap.data().contact);

        setEditProductModal(true);
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
        alert("No such document!");
      }
    } catch (error) {
      console.log("Error getting document:", error);
      alert("Error getting document:", error);
    }
  };


  const handleItemSold = async () => {
    try {
      const productRef = doc(db, "products", id);
      await updateDoc(productRef, {
        status: "sold",
      });
      message.success("Alaabta waxaa la iibiyay");
      window.location.reload();
    } catch (error) {
      message.error("Laguma guulaysan in alaabta loo calaamadiyo in la iibiyay");
    }
  };


  const handleItemAvailable = async () => {
    try {
      const productRef = doc(db, "products", id);
      await updateDoc(productRef, {
        status: "available",
      });
      message.success("Ka dhig Alaatu ay taalo");
      window.location.reload();
    } catch (error) {
      message.error("Laguma guulaysan in alaabta loo calaamadiyo in ay taalo");

    }
  };

  // delete product
  const handleDeleteProduct = async () => {
    try {
      const docRef = doc(db, "products", id);
      await deleteDoc(docRef);
      message.success("Alaabta waxaa la tirtiray");

      // Remove the product from the cached products
      const cachedProducts = JSON.parse(localStorage.getItem('products'));
      if (cachedProducts) {
        const updatedProducts = cachedProducts.filter((product) => product.id !== id);
        localStorage.setItem('products', JSON.stringify(updatedProducts));

      }

      navigate('/');
    } catch (error) {
      message.error("Laguma guulaysan in alaabta la tirtiro");
    }
  };


  useEffect(() => {
    const fetchProductDetails = async () => {
      try {
        const docRef = doc(db, "products", id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setProductDetails(docSnap.data());
          setProductLoading(false);
          // increment views
          updateDoc(doc(db, "products", id), {
            views: increment(1),
          });
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
          setProductLoading(false);
        }
      } catch (error) {
        console.log("Error getting document:", error);
        setProductLoading(false);
      }

    };
    fetchProductDetails();
  }
    , [id]);

  const translateCategory = (productCategory) => {
    switch (productDetails?.category) {
      case "all":
        return "Dhamaan Gobolada";
      case "Vehicles":
        return "Gaadiid";
      case "Properties":
        return "Dhul ama Guryo";
      case "Mobile":
        return "Telefoono";
      case "Electronics":
        return "Elektrooniga";
      case "Food":
        return "Raashin";
      case "Clothes":
        return "Dhar";
      case "Health":
        return "Dawooyin";
      case "Babies":
        return "Alaabta Caruurta";
      case "Home":
        return "Qurxinta Guryaha";
      case "Services":
        return "Adeegyo";
      case "Jobs":
        return "Shaqo";
      case "Other":
        return "Wax kale";
      default:
        return "";
    }
  };




  return (
    <>

      {productLoading ? (
        <div role="status">
          <svg aria-hidden="true" class="absolute top-1/2 left-1/2 w-16 h-16 text-gray-200 animate-spin  fill-green-500" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
          </svg>
          <span class="sr-only">Loading...</span>
        </div>
      ) : (
        <>

          {/* // Edit Product Modal */}
          <Transition.Root show={editProductModal} as={Fragment}>
            <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setEditProductModal}>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              <div className="fixed inset-0 z-10 overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  >
                    <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                      <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                        <div>
                          <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-orange-100">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                              <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                            </svg>

                          </div>
                          <div className="mt-3 text-center sm:mt-5">
                            <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                              Wax ka badal Alaabta
                            </h3>
                            <div className="mt-2">
                              <p className="text-sm text-gray-500">
                                Buuxi foomka hoose si aad wax uga badasho alaabtaada
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="mt-5">
                          <div className="grid grid-cols-1 gap-y-6 sm:grid-cols-6 sm:gap-x-6">

                            <div className="col-span-6">
                              <label htmlFor="category" className="block text-sm font-medium text-gray-700">
                                Gobolka Alaabtu taalo
                              </label>
                              <div className="mt-1">
                                <select
                                  id="category"
                                  name="category"
                                  className="shadow-sm focus:ring-orange-500 focus:border-orange-500 block w-full sm:text-sm border-orange-300 rounded-md"
                                  onChange={(e) => setProductLocation(e.target.value)}
                                  value={productLocation}
                                >
                                  <option value="all">Dhamaan Gobolada</option>
                                  <option value="Awdal">Awdal</option>
                                  <option value="Maroodi Jeex">Maroodi Jeex</option>
                                  <option value="Sahil">Saaxil</option>
                                  <option value="Togdheer">Togdheer</option>
                                  <option value="Sool">Sool</option>
                                  <option value="Sanaag">Sanaag</option>
                                  <option value="Other">Qaar kale</option>
                                </select>
                              </div>
                            </div>

                            <div className="col-span-6">
                              <label htmlFor="category" className="block text-sm font-medium text-gray-700">
                                Nooca Alaabta
                              </label>
                              <div className="mt-1">
                                <select
                                  id="category"
                                  name="category"
                                  className="shadow-sm focus:ring-orange-500 focus:border-orange-500 block w-full sm:text-sm border-orange-300 rounded-md"

                                  value={productCategory}
                                  onChange={(e) => setProductCategory(e.target.value)}
                                >
                                  <option value="">Dooro Nooca Alaabta</option>
                                  <option value="Vehicles">Gaadiid</option>
                                  <option value="Properties">Dhul ama Guryo</option>
                                  <option value="Mobile">Telefoono</option>
                                  <option value="Electronics">Elektrooniga</option>
                                  <option value="Food">Raashin</option>
                                  <option value="Clothes">Dhar</option>
                                  <option value="Babies">Alaabta Caruurta</option>
                                  <option value="Home">Qurxinta Guryaha</option>
                                  <option value="Services">Adeegyo / Shaqo</option>
                                  <option value="Heavy Equipment">Qalabka Culus</option>
                                  <option value="Other">Wax kale</option>
                                </select>
                              </div>
                            </div>


                            <div className="col-span-6">
                              <label htmlFor="description" className="block text-sm font-medium text-gray-700">
                                Faahfaahin Alaabta
                              </label>
                              <div className="mt-1">
                                <textarea
                                  id="description"
                                  name="description"
                                  rows={3}
                                  placeholder="e.g. 'Moobil cusub oo IPHONE 12 PRO MAX ah, qaadkiiso yahay 256GB, midabku Buluug yahay'"
                                  className="shadow-sm focus:ring-orange-500 focus:border-orange-500 mt-1 block w-full sm:text-sm border-orange-300 rounded-md"
                                  value={productDescription}
                                  onChange={(e) => setProductDescription(e.target.value)}
                                />
                              </div>
                            </div>

                            <div className="col-span-6">
                              <label htmlFor="ticket-price" className="block text-sm font-medium leading-6 text-gray-900">
                                Qiimaha Alaabta (Dollar)
                              </label>
                              <div className="mt-1 flex rounded-md shadow-sm">
                                <span className="inline-flex items-center rounded-l-md border border-r-0 border-orange-300 px-3 text-orange-500 sm:text-sm">
                                  $
                                </span>
                                <input
                                  type="number"
                                  name="ticket-price"
                                  id="ticket-price"
                                  className="block w-full flex-1 rounded-none rounded-r-md border-0 py-2 text-gray-900 ring-1 ring-inset ring-orange-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6"
                                  placeholder="0.00"
                                  aria-describedby="ticket-price-currency"
                                  value={productPrice}
                                  defaultValue={''}
                                  onChange={(e) => setProductPrice(e.target.value)}
                                />
                              </div>
                            </div>
                            <div className="col-span-6 sm:col-span-6">
                              <label htmlFor="phone-address" className="block text-sm font-medium leading-6 text-gray-900">
                                WhatsApp Number <a className='text-red-600' >(Fadlan ka bilaw 65 ama 63)</a>
                              </label>
                              <div className="mt-2 flex rounded-md shadow-sm">
                                <span className="inline-flex items-center rounded-l-md border border-r-0 border-orange-300 px-3 text-gray-500 sm:text-sm">
                                  +252
                                </span>
                                <input
                                  type="text"
                                  name="phone-website"
                                  id="phone-website"
                                  className="block w-full flex-1 rounded-none rounded-r-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-orange-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6"
                                  placeholder=" 63 4123456"
                                  aria-describedby="phone-website-description"
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    if (value === '' || (/^[6][0-9]{0,8}$/.test(value))) {
                                      setProductContact(value);
                                    }
                                  }}
                                  value={productContact}
                                />
                              </div>
                            </div>

                          </div>

                        </div>
                      </div>
                      <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                        <button
                          type="button"
                          className="inline-flex w-full justify-center rounded-md bg-orange-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 sm:ml-3 sm:w-auto"
                          onClick={(e) => handleEditProduct(e)}
                        >
                          Cusboonaysii
                        </button>
                        <button
                          type="button"
                          className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                          onClick={() => setEditProductModal(false)}
                          ref={cancelButtonRef}
                        >
                          Ka Noqo
                        </button>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition.Root>

          <div className="bg-white">
            <div className="max-w-7xl  mx-auto py-16 px-4 sm:px-6 lg:px-8">

              <div className="mb-4">
                <Link to="/">
                  <button className="bg-green-500 hover:bg-green-700 text-white py-2 px-4 rounded">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 inline-block" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                        d="M10 19l-7-7m0 0l7-7m-7 7h18" />
                    </svg>
                    <a className="inline-block  ml-2">Ku noqo Alaabaha</a>

                  </button>
                </Link>
              </div>
              <div className="lg:grid p-2 bg-green-400 lg:grid-cols-2 lg:gap-x-8 lg:items-start">
                <h1 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                  {productDetails?.name}
                </h1>
              </div>
              <div className="mt-8 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:items-start">
                <div className="relative lg:row-start-1 lg:col-start-2">
                  <svg
                    className="hidden lg:block absolute top-0 right-0 -mt-20 -mr-20"
                    width={404}
                    height={384}
                    fill="none"
                    viewBox="0 0 404 384"
                    aria-hidden="true"
                  >
                    <defs>
                      <pattern
                        id="de316486-4a29-4312-bdfc-fbce2132a2c1"
                        x={0}
                        y={0}
                        width={20}
                        height={20}
                        patternUnits="userSpaceOnUse"
                      >
                        <rect
                          x={0}
                          y={0}
                          width={4}
                          height={4}
                          className="text-green-200"
                          fill="currentColor"
                        />
                      </pattern>
                    </defs>
                    <rect
                      width={404}
                      height={384}
                      fill="url(#de316486-4a29-4312-bdfc-fbce2132a2c1)"
                    />
                  </svg>
                  <div className="relative mx-auto rounded-lg shadow-lg md:max-w-2xl sm:mx-auto lg:max-w-full">
                    {/* <Image
                width={`100%`}
                src={productDetails?.image}
                alt={productDetails?.name}
              /> */}

                    <Image.PreviewGroup
                      items={productDetails?.image?.map((image) => ({
                        src: image,
                      }))}>
                      <Image width={`100%`} src={productDetails?.image[0]} />

                    </Image.PreviewGroup>

                  </div>
                </div>
                <div className="mt-10 lg:mt-0">
                  <div className="mt-4">
                    <h2 className="">Warbixin ku saabsan badeecaddan </h2>
                    <p className="text-3xl text-gray-900">$ {productDetails?.price}</p>
                    <p className="mt-6 text-gray-500">{productDetails?.description}</p>
                  </div>
                  <div className="mt-10">
                    <h2 className="text-sm font-bold text-gray-900 ">Goobta Alaabtu Taal </h2>
                    <p className="mt-4 text-gray-500">{
                      productDetails?.location === "All" ? "Dhammaan Gobolada oo Dhan" : productDetails?.location}</p>
                  </div>
                  <div className="mt-10">
                    <h2 className="text-sm font-bold text-gray-900 ">Nooca Alaabta</h2>
                    <p className="mt-4 text-gray-500">
                      {translateCategory(productDetails?.category)}
                    </p>


                  </div>


                  {productDetails?.owner !== user?.uid && (
                    <div className="mt-10 rounded-md bg-orange-300 p-4">
                      <h2 className="text-sm font-bold text-gray-900 ">Talooyin Muhiim ah</h2>
                      <ul className="mt-4 pl-4 list-disc text-sm space-y-2">
                        <li className=" text-gray-900">
                          Kula kulan Iibiyaha meel dadweyne ah ama dad badan joogaan
                        </li>
                        <li className="text-gray-900">
                          Iska hubi Alaabta inta aanad iibsan
                        </li>
                        <li className="text-gray-900">
                          Lacagta ha bixin ilaa aad aragto oo aad ku qanacto Alaabta
                        </li>
                      </ul>
                    </div>
                  )}
                  <div className="mt-10">

                    {productDetails?.owner === user?.uid && productDetails?.status !== "sold" && (
                      <>
                        <div className="grid grid-cols-2 gap-4">
                          <button
                            onClick={() => window.confirm("Ma hubtaa in Alaabta la tirtiro?") && handleDeleteProduct()}
                            className="bg-red-500 hover:bg-red-700 text-white w-full py-2 px-4 rounded">
                            La iibiyay Alaabta (Tirtir)
                          </button>

                          <button
                            onClick={() => handleFetchProductForEdit()}
                            className="bg-orange-500 hover:bg-orange-600 text-white w-full py-2 px-4 rounded">
                            Wax ka badal Alaabta
                          </button>
                        </div>


                      </>

                    )}

                    {productDetails?.owner === user?.uid && productDetails?.status === "sold" && (
                      <button
                        onClick={() => handleItemAvailable()}
                        className="bg-orange-500 hover:bg-orange-700 text-white w-full py-2 px-4 rounded">
                        Ka dhig Alaabta in ay taalo
                      </button>
                    )}

                    {productDetails?.owner !== user?.uid && (
                      <>

                        <div className="grid grid-cols-1 gap-4 mt-4 sm:grid-cols-2">
                          <button
                            onClick={() => {
                              if (window.confirm("Fadlan kula kulan iibiyaha meel dadweyne ah ama dad badan joogaan, iska hubi Alaabta, lacagta ha bixin ilaa aad aragto oo aad ku qanacto Alaabta")) {
                                const domainName = window.location.hostname;
                                window.open(`https://api.whatsapp.com/send?phone=252${productDetails?.contact}&text=Salaan, waxaan ku arkay Alaabtaada magaceedu yahay ${productDetails?.name} ${domainName}. Wali ma taalaa?`, "_blank");
                              }
                            }}
                            className="flex items-center justify-center bg-green-500 hover:bg-green-700 text-white w-full py-2 px-4 col-span-1 rounded">

                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 mr-4">
                              <path stroke-linecap="round" stroke-linejoin="round" d="M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 01.865-.501 48.172 48.172 0 003.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z" />
                            </svg>
                            La xidhiidh Iibiyaha
                          </button>
                          <button
                            onClick={() => {
                              if (window.confirm("Fadlan kula kulan iibiyaha meel dadweyne ah ama dad badan joogaan, iska hubi Alaabta, lacagta ha bixin ilaa aad aragto oo aad ku qanacto Alaabta")) {
                                window.open(`tel:+252${productDetails?.contact}`, "_blank");
                              }
                            }}
                            className="flex items-center justify-center bg-blue-500 hover:bg-blue-700 text-white w-full py-2 px-4 col-span-1 rounded">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 mr-4">
                              <path stroke-linecap="round" stroke-linejoin="round" d="M20.25 3.75v4.5m0-4.5h-4.5m4.5 0l-6 6m3 12c-8.284 0-15-6.716-15-15V4.5A2.25 2.25 0 014.5 2.25h1.372c.516 0 .966.351 1.091.852l1.106 4.423c.11.44-.054.902-.417 1.173l-1.293.97a1.062 1.062 0 00-.38 1.21 12.035 12.035 0 007.143 7.143c.441.162.928-.004 1.21-.38l.97-1.293a1.125 1.125 0 011.173-.417l4.423 1.106c.5.125.852.575.852 1.091V19.5a2.25 2.25 0 01-2.25 2.25h-2.25z" />
                            </svg>

                            Wac Iibiyaha

                          </button>
                        </div>

                      </>

                    )}

                  </div>
                  {/* <div className="mt-10">
                    <button onClick={() => handleDeleteProduct()} className="bg-red-500 hover:bg-red-700 text-white w-full py-2 px-4 rounded">
                      Tirtir Alaabta
                    </button>

                  </div> */}
                </div>

              </div>

              {productDetails?.image > 1 && (
                <>
                  <h2 className="text-2xl font-extrabold tracking-tight text-gray-900 mt-16">
                    Sawiro Dheeraad ah
                  </h2>

                  <div className="mt-4">





                    <Image.PreviewGroup>
                      {productDetails?.image?.map((image) => (
                        <Image
                          width={`20vw`}
                          height={`20vw`}
                          style={{
                            objectFit: "cover",
                          }}
                          src={image}
                        />
                      ))}
                    </Image.PreviewGroup>
                  </div>
                </>
              )}



              <div className=" mt-16">
                <h2 className="text-2xl font-extrabold tracking-tight text-gray-900">Alaabo lamida</h2>
                <p className="mt-4 text-gray-500">Halkan ka arag alaabo lamida alaabta aad eegtay</p>
                {loadSimilarProducts === false && (
                <button 
                onClick={() => setLoadSimilarProducts(!loadSimilarProducts)}
                className="bg-green-500 hover:bg-green-700 text-white w-full py-2 px-4 rounded mt-4">
                  Itus Alaabo Lamida
                </button>
              )}



                <div className=" mt-10 space-y-12 lg:space-y-0 lg:grid lg:grid-cols-4 lg:gap-x-8">


                  {similarProducts?.map((similarProduct) => (
                    <a key={similarProduct?.id} className='' href={`/productDetails/${similarProduct?.id}`}>

                      <div className="aspect-w-3 aspect-h-4 overflow-hidden">
                        <img
                          src={similarProduct?.image}
                          alt={similarProduct?.imageAlt}
                          className="object-center object-cover hover:opacity-75"
                        />
                      </div>

                      <div className="flex justify-between items-center">
                        <p className="mt-4 text-sm font-medium text-gray-900">$ {similarProduct.price}</p>
                        <div className="flex items-center ">

                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 mt-4 mr-2 text-gray-500 ">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                            <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                          </svg>

                          <p className="mt-4 text-sm font-medium text-gray-900">{similarProduct?.views ? similarProduct.views : 0}</p>
                        </div>
                      </div>
                      <div className="">
                      <h3 className="mb-1 text-lg text-gray-700 hover:underline font-semibold">
                        {similarProduct.name}
                      </h3>
                      </div>

                    </a>
                  ))}

                </div>
              </div>
            </div>
          </div>

        </>
      )}
    </>

  )

}
export default ProductDetails