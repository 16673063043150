import React from 'react'
import { Link } from 'react-router-dom'

const MyListings = () => {
  return (
    <div>
        <h1>My Listings</h1>
        <Link to="/">Back to Home</Link>
    </div>
  )
}

export default MyListings