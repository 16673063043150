import { useNavigate } from 'react-router-dom';
import {
  collection,
  addDoc,
  serverTimestamp,
  doc,
  getDocs,
  orderBy,
  getDoc,
  query,
} from "firebase/firestore";
import { db, storage } from "../firebase";
import { Fragment, useRef, useState, useEffect } from 'react'
import Header from './Header.jsx';
// import demoProducts from '../database/demoProducts.json';
import { Dialog, Transition } from '@headlessui/react'
// import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import { useAuth } from '../context/AuthContext.js';
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { Image, Tag, Pagination, ConfigProvider } from 'antd';

const CACHE_DURATION = 24 * 60 * 60 * 1000; // 24 hours
const PRODUCTS_KEY = 'products';
const PRODUCTS_TIMESTAMP_KEY = 'latestUpdate';

export default function Hero() {
  // import demoProducts from '../database/demoProducts.json';
  const { user } = useAuth();
  const navigate = useNavigate();

  // product fetching
  const [productList, setProductList] = useState([]);
  const [loading, setLoading] = useState(true);

  // adding new product
  const [addProductModal, setAddProductModal] = useState(false);
  const [productCategory, setProductCategory] = useState('');
  const [productLocation, setProductLocation] = useState('');
  const [productName, setProductName] = useState('');
  const [productDescription, setProductDescription] = useState('');
  const [productPrice, setProductPrice] = useState('');
  const [productContact, setProductContact] = useState('');
  const [productImages, setProductImages] = useState([]);
  const [productImageUploading, setProductImageUploading] = useState(false);
  const [productError, setProductError] = useState('');
  const cancelButtonRef = useRef(null);


  // device size
  const [deviceSize, setDeviceSize] = useState(0);

  // filters
  const [productFilterCategory, setProductFilterCategory] = useState('all');
  const [productFilterLocation, setProductFilterLocation] = useState('all');
  const [productFilterSearch, setProductFilterSearch] = useState('');
  const [productFilterOwnerView, setProductFilterOwnerView] = useState(false);
  const [productFiltered, setProductFiltered] = useState([]);

  // adding server side pagination
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 24;
  const [totalNumberOfProducts, setTotalNumberOfProducts] = useState(3);
  // Add pagination to the list of products
  // Calculate the indices of the first and last products on the current page
  const indexOfLastProduct = currentPage * itemsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - itemsPerPage;
  const currentProducts = productFiltered.slice(indexOfFirstProduct, indexOfLastProduct);

  // Use currentProducts instead of productFiltered for rendering the product list

  // Using local storage when user uploades new product, to know is there is a new product
  const [localStorageProducts, setLocalStorageProducts] = useState(false);
  const [submissionStatus, setSubmissionStatus] = useState(() => {
    const submissionStatus = localStorage.getItem('mySubmissions');
    return submissionStatus ? JSON.parse(submissionStatus) : null;
  });
  const [mySubmittedProducts, setMySubmittedProducts] = useState([]);

  useEffect(() => {
    setTotalNumberOfProducts(productFiltered.length);
  }, [productFiltered]);


  // small device responsive
  useEffect(() => {
    const handleResize = () => {
      setDeviceSize(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // check if there is a cached version of the products, if yes, use it if not, fetch from firebase
  const fetchProducts = () => {
    const cachedProducts = JSON.parse(localStorage.getItem(PRODUCTS_KEY));
    const cachedProductsTimestamp = JSON.parse(localStorage.getItem(PRODUCTS_TIMESTAMP_KEY));
    const now = new Date().getTime();
    if (cachedProducts && cachedProductsTimestamp && now - cachedProductsTimestamp < CACHE_DURATION) {
      setProductList(cachedProducts);
      setLoading(false);
      // alert('Nah fam, we are using the cached version')
    } else {
      // alert('Nah fam, dunno what happened')
    }
  }


  // fetch products from firebase
  const fetchProductsFromFirebase = async () => {
    const q = query(collection(db, "products"), orderBy("updatedAt", "desc")); //  limit(itemsPerPage)
    const querySnapshot = await getDocs(q);
    const products = [];
    querySnapshot.forEach((doc) => {
      products.push({ ...doc.data(), id: doc.id });
    });
    setProductList(products);
    setLoading(false);
    try {
      localStorage.setItem(PRODUCTS_KEY, JSON.stringify(products));
      localStorage.setItem(PRODUCTS_TIMESTAMP_KEY, JSON.stringify(new Date().getTime()));
      // alert('Nah fam, we are using the firebase version to cache' + PRODUCTS_TIMESTAMP_KEY)
    } catch (error) {
      console.log(error);
      alert(error);
    }

  };


  useEffect(() => {
    const checkIfThereisUpdateinFirebase = async () => {
      setLoading(true);
      const docRef = doc(db, "analytics", "readCalls");
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const latestUpdate = docSnap.data().latestUpdate; // Assuming this is a Firestore Timestamp object
        const latestUpdateTime = latestUpdate.toMillis(); // Convert to Unix epoch time in milliseconds
        const cachedProductsTimestamp = JSON.parse(localStorage.getItem(PRODUCTS_TIMESTAMP_KEY));

        
        if (latestUpdateTime > cachedProductsTimestamp) {
          fetchProductsFromFirebase();
          // alert('Fam, Firebase time fetched is ' + latestUpdateTime);
        } else {
          // console.log('Local Time ' + cachedProductsTimestamp + ', Firebase time ' + latestUpdateTime + ', Meaning' + (cachedProductsTimestamp - latestUpdateTime));
          fetchProducts();
        }

        // if loading takes more than 5 seconds just call fetchProductsFromFirebase function
        // if (loading) {
        //   setTimeout(() => {
        //     fetchProductsFromFirebase();
        //     alert('Fam, Cache is taking too long');
        //   }, 20000);
        // }
      
      }
      
    }
    checkIfThereisUpdateinFirebase();
  }, []);

  // ---------------------------------------------- TEMPORARY SOLUTION ----------------------------------------------
  useEffect(() => {
    const submissions = JSON.parse(localStorage.getItem('mySubmissions')) || [];
    setMySubmittedProducts(submissions);
  }, []);





  // Applying Category, Location, and Search Filters
  const applyFilters = () => {
    let filtered = productList;
    if (productFilterCategory !== 'all') {
      filtered = filtered.filter((product) => product.category === productFilterCategory);
    }
    if (productFilterLocation !== 'all') {
      filtered = filtered.filter((product) => product.location === productFilterLocation);
    }
    if (productFilterSearch !== '') {
      filtered = filtered.filter((product) => product.name.toLowerCase().includes(productFilterSearch.toLowerCase()));
    }
    if (productFilterOwnerView === true) {
      filtered = filtered.filter((product) => product?.owner === user?.uid);
    }
    return filtered;
  }

  useEffect(() => {
    const filtered = applyFilters();
    setProductFiltered(filtered);
  }, [productList, productFilterCategory, productFilterLocation, productFilterSearch, productFilterOwnerView]);


  // handle product upload
  const handleAddProduct = async (e) => {
    e.preventDefault();
    setProductError('');
    if (productCategory === '') {
      setProductError('Fadlan dooro Nooca Alaabta');
      return;
    }
    if (productLocation === '') {
      setProductError('Fadlan dooro Gobolka Alaabta taalo');
      return;
    }
    if (productName === '') {
      setProductError('Fadlan gali Magaca Alaabta');
      return;
    }
    if (productDescription === '') {
      setProductError('Fadlan gali Faahfaahin ku saabsan Alaabta');
      return;
    }
    if (productPrice === '') {
      setProductError('Fadlan gali Qiimaha Alaabta');
      return;
    }
    if (productContact === '') {
      setProductError('Fadlan gali Numberkaaga WhatsApp');
      return;
    }
    
    try {
      const productRef = await addDoc(collection(db, "products"), {
        category: productCategory,
        location: productLocation,
        name: productName,
        description: productDescription,
        price: productPrice,
        contact: productContact,
        image: productImages,
        owner: user ? user.uid : null,
        updatedAt: serverTimestamp(),
        approved: false,
        views: 0,
        status: 'available',
      });
      alert('Alaabta waad kuso dartay');
      setAddProductModal(false);
      setProductCategory('');
      setProductLocation('');
      setProductName('');
      setProductDescription('');
      setProductPrice('');
      setProductContact('');
      setProductImages([]);
      setProductError('');
      fetchProductsFromFirebase();
      navigate('/');

      // updating local storage
      try {
        // Retrieving the item from local storage and parse it as an array.
        // If it doesn't exist, defaulting to an empty array.
        const mySubmissions = JSON.parse(localStorage.getItem('mySubmissions')) || [];
      
        // Pushing the new item into the array.
        mySubmissions.push(productRef.id);
      
        // Saving the updated array back into local storage.
        localStorage.setItem('mySubmissions', JSON.stringify(mySubmissions));
        setLocalStorageProducts(true);
      } catch (error) {
        console.log(error);
        alert('Error updating local storage' + error);
      }
      
     
    } catch (error) {
      console.log(error);
      alert('Laguma guulaysan in Alaabta lagu soo daro');
    }

  }

  const mySubmissions = JSON.parse(localStorage.getItem('mySubmissions'));
  // handle image upload
  const handleImageUpload = async (e) => {
    setProductImageUploading(true);
    const files = e.target.files;
    const uploadedImageUrls = [];

    for ( const file of files) {
      const uniqueId = Date.now();
      const storageRef = ref(storage, `products/${uniqueId}`);
      try{
        await uploadBytes(storageRef, file);
        const url = await getDownloadURL(storageRef);
        uploadedImageUrls.push(url);
        setProductImageUploading(false);
      } catch (error) {
        console.log(error);
        alert(error.message);
      }
    }

    setProductImages([...productImages, ...uploadedImageUrls]);
  }

  // deleting cached products
  const deleteCachedProducts = () => {
    localStorage.removeItem(PRODUCTS_KEY);
    localStorage.removeItem(PRODUCTS_TIMESTAMP_KEY);
  }
// Prompting the user to install the PWA
const [installPromptEvent, setInstallPromptEvent] = useState(null);
const [showInstallModal, setShowInstallModal] = useState(true);

useEffect(() => {
  const handleBeforeInstallPrompt = (event) => {
    event.preventDefault();
    setInstallPromptEvent(event);
    setShowInstallModal(true);
  };

  window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

  return () => {
    window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
  };
}, []);

const handleInstallClick = () => {
  setShowInstallModal(false);
  if (installPromptEvent) {
    installPromptEvent.prompt();
    installPromptEvent.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === 'accepted') {
        console.log('User accepted the A2HS prompt');
      } else {
        console.log('User dismissed the A2HS prompt');
      }
      setInstallPromptEvent(null);
    });
  }else {
    console.log('No event to prompt');
  }
};


return (
  <div className="">
    <Header />
    <div className="bg-white">
      {/* Products Section */}
      <div className="bg-white max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Install PWA */}
        {showInstallModal && !window.matchMedia('(display-mode: standalone)').matches && deviceSize < 415 && ( 
          
        <Transition.Root show={showInstallModal} as={Fragment}>
          <Dialog as="div" className="fixed inset-0 z-10 overflow-y-auto" onClose={setShowInstallModal}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-50" />
            </Transition.Child>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
                <div className="bg-white p-6 rounded-lg shadow-lg">
                  <h2 className="text-2xl font-bold mb-4">Install-garee Iibiye</h2>
                  <p className="mb-4">Lasoo App-ka Iibiye, si aanad ugu baahan inaad meel kasoo gasho.</p>
                  <div className="flex justify-end">
                    <button
                      className="bg-green-500 text-white px-4 py-2 rounded-lg mr-2"
                      onClick={handleInstallClick}
                    >
                      Install
                    </button>
                    <button
                      className="bg-gray-300 text-gray-700 px-4 py-2 rounded-lg"
                      onClick={() => setShowInstallModal(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </Dialog>
        </Transition.Root>
      )}
        {/* ... */}


          {/* <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
            <div className="bg-white p-6 rounded-lg shadow-lg">
              <h2 className="text-2xl font-bold mb-4">Install Our App</h2>
              <p className="mb-4">Get a better experience by installing our app on your device.</p>
              <div className="flex justify-end">
                <button
                  className="bg-green-500 text-white px-4 py-2 rounded-lg mr-2"
                  onClick={handleInstallClick}
                >
                  Install
                </button>
                <button
                  className="bg-gray-300 text-gray-700 px-4 py-2 rounded-lg"
                  onClick={() => setShowInstallModal(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div> */}
        
        {/* ... */}
    
  

    

          {/* Location, Category, and Search Filters */}
          {deviceSize > 415 ? (
            <>
              <div className="flex max-w-7xl mx-auto lg:px-0 space-x-4 justify-between  ">
                <select
                  onChange={(e) => {
                    setProductFilterLocation(e.target.value);
                  }}
                  defaultValue={'all'}
                  className="bg-white focus:outline-none focus:ring-2 focus:ring-green-600 focus:border-transparent rounded-lg pr-8 py-2 max-w-xs border-2 border-green-500"
                >
                  <option value="all">Dhamaan Gobolada</option>
                  <option value="Awdal">Awdal</option>
                  <option value="Maroodi Jeex">Maroodi Jeex</option>
                  <option value="Sahil">Saaxil</option>
                  <option value="Togdheer">Togdheer</option>
                  <option value="Sool">Sool</option>
                  <option value="Sanaag">Sanaag</option>
                  <option value="Other">Qaar kale</option>
                </select>

                <select
                  onChange={(e) => {
                    setProductFilterCategory(e.target.value);
                  }}
                  
                  className='bg-white focus:outline-none focus:ring-2 focus:ring-green-600 focus:border-transparent rounded-lg pr-8 py-2 max-w-xs border-2 border-green-500'
                >
                  <option value="all">Nooc kasta</option>
                  <option value="Vehicles">Gaadiid</option>
                  <option value="Properties">Dhul ama Guryo</option>
                  <option value="Mobile">Telefoono</option>
                  <option value="Electronics">Elektrooniga</option>
                  <option value="Food">Raashin</option>
                  <option value="Clothes">Dhar</option>
                  <option value="Babies">Alaabta Caruurta</option>
                  <option value="Home">Qurxinta Guryaha</option>
                  <option value="Services">Adeegyo / Shaqo</option>
                  <option value="Heavy Equipment">Qalabka Culus</option>
                  <option value="Other">Qaar kale</option>

                </select>
              </div>
            </>
          ) : (
            <>
              <div className="flex   lg:px-0  justify-between  ">
                <select
                  onChange={(e) => {
                    setProductFilterLocation(e.target.value);
                  }}
                  className="bg-white focus:outline-none focus:ring-2 focus:ring-green-600 focus:border-transparent rounded-lg pr-4 py-2 max-w-xs border-2 border-green-500"
                >
                  <option value="all">Dhamaan Gobolada</option>
                  <option value="Awdal">Awdal</option>
                  <option value="Maroodi Jeex">Maroodi Jeex</option>
                  <option value="Sahil">Saaxil</option>
                  <option value="Togdheer">Togdheer</option>
                  <option value="Sool">Sool</option>
                  <option value="Sanaag">Sanaag</option>
                  <option value="Other">Qaar kale</option>
                </select>

                <select
                  onChange={(e) => {
                    setProductFilterCategory(e.target.value);
                  }}
                  className='bg-white focus:outline-none focus:ring-2 focus:ring-green-600 focus:border-transparent rounded-lg pr-1 py-1 max-w-xs border-2 border-green-500'
                >
                  <option value="all">Nooc kasta</option>
                  <option value="Vehicles">Gaadiid</option>
                  <option value="Properties">Dhul ama Guryo</option>
                  <option value="Mobile">Telefoono</option>
                  <option value="Electronics">Elektrooniga</option>
                  <option value="Food">Raashin</option>
                  <option value="Clothes">Dhar</option>
                  <option value="Babies">Alaabta Caruurta</option>
                  <option value="Home">Qurxinta Guryaha</option>
                  <option value="Services">Adeegyo / Shaqo</option>
                  <option value="Heavy Equipment">Qalabka Culus</option>
                  <option value="Other">Qaar kale</option>

                </select>
              </div>
            </>
          )}
          <div className="flex justify-between items-center md:justify-start md:space-x-10">
            <input
              type="text"
              placeholder="Raadi Alaab"
              onChange={(e) => setProductFilterSearch(e.target.value)}
              className="bg-white focus:outline-none focus:ring-2 focus:ring-green-600 focus:border-transparent rounded-lg my-3 px-4 py-2 w-full border-2 border-green-500" />
          </div>

          {/* Add New Product */}
          <div className={`flex max-w-7xl mx-auto lg:px-0 space-x-4 ${user !== null ? 'justify-between' : 'justify-end'}`}>

            {user !== null && (
              <button
                onClick={() => setProductFilterOwnerView(!productFilterOwnerView)}
                className="flex items-center justify-center bg-green-500 hover:bg-green-700 text-white mb-3 px-4 py-2 rounded border-2 border-green-500 w-44">
                <span>
                  {productFilterOwnerView ? "Itus Alaabkasta" : "Itus Alaabtayda"}
                </span>
              </button>
            )}
           

            <button
              onClick={() => setAddProductModal(true)}
              className="flex items-center justify-center bg-green-500 hover:bg-green-700 text-white mb-3 px-4 py-2 rounded border-2 border-green-500 w-44">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
              </svg>
              <span>
                Iibi Alaab
              </span>
            </button>

          </div>

          <Transition.Root 
          show={addProductModal} as={Fragment}
          >
          <Dialog as="div" className="relative z-10" 
          initialFocus={cancelButtonRef} 
          onClose={() => setAddProductModal(false)}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                    <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                      <div>
                        <div className="mx-auto flex items-center justify-center text-green-500 h-12 w-12 rounded-full bg-green-100">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                          </svg>

                        </div>
                        <div className="mt-3 text-center sm:mt-5">
                          <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                            Kusoo dar Alaab cusub
                          </h3>
                          <div className="mt-2">
                            <p className="text-sm text-gray-500">
                              Buuxi foomka hoose si aad ugu darto alaab cusub.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="mt-5">
                        <div className="grid grid-cols-1 gap-y-6 sm:grid-cols-6 sm:gap-x-6">

                          <div className="col-span-6">
                            <label htmlFor="category" className="block text-sm font-medium text-gray-700">
                              Gobolka Alaabtu taalo
                            </label>
                            <div className="mt-1">
                              <select
                                id="category"
                                name="category"
                                className="shadow-sm focus:ring-green-500 focus:border-green-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                defaultValue={'all'}
                               onChange={(e) => setProductLocation(e.target.value)}
                                value={productLocation}
                              >
                                <option value="all">Dhamaan Gobolada</option>
                                <option value="Awdal">Awdal</option>
                                <option value="Maroodi Jeex">Maroodi Jeex</option>
                                <option value="Sahil">Saaxil</option>
                                <option value="Togdheer">Togdheer</option>
                                <option value="Sool">Sool</option>
                                <option value="Sanaag">Sanaag</option>
                                <option value="Other">Qaar kale</option>
                              </select>
                            </div>
                          </div>

                          <div className="col-span-6">
                            <label htmlFor="category" className="block text-sm font-medium text-gray-700">
                              Nooca Alaabta
                            </label>
                            <div className="mt-1">
                              <select
                                id="category"
                                name="category"
                                className="shadow-sm focus:ring-green-500 focus:border-green-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                defaultValue={''}
                                value={productCategory}
                                onChange={(e) => setProductCategory(e.target.value)}
                              >
                                <option value="">Dooro Nooca Alaabta</option>
                                <option value="Vehicles">Gaadiid</option>
                                <option value="Properties">Dhul ama Guryo</option>
                                <option value="Mobile">Telefoono</option>
                                <option value="Electronics">Elektrooniga</option>
                                <option value="Food">Raashin</option>
                                <option value="Clothes">Dhar</option>
                                <option value="Babies">Alaabta Caruurta</option>
                                <option value="Home">Qurxinta Guryaha</option>
                                <option value="Services">Adeegyo / Shaqo</option>
                                <option value="Heavy Equipment">Qalabka Culus</option>
                                <option value="Other">Wax kale</option>
                              </select>
                            </div>
                          </div>

                          <div className="col-span-6">
                            <label htmlFor="title" className="block text-sm font-medium text-gray-700">
                              Magaca Alaabta
                            </label>
                            <div className="mt-1">
                              <input
                                type="text"
                                name="title"
                                id="title"
                                placeholder='tusaale: Toyota Harrier 2010'
                                className="shadow-sm focus:ring-green-500 focus:border-green-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                defaultValue={''}
                                value={productName}
                                onChange={(e) => setProductName(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="col-span-6">
                            <label htmlFor="description" className="block text-sm font-medium text-gray-700">
                              Faahfaahin Alaabta
                            </label>
                            <div className="mt-1">
                              <textarea
                                id="description"
                                name="description"
                                rows={3}
                                placeholder="tusaale: Toyota Harrier 2010, cad, 4x4, automatic, 4 shaag cusub, 5000km socday"
                                className="shadow-sm focus:ring-green-500 focus:border-green-500 mt-1 block w-full sm:text-sm border-gray-300 rounded-md"
                                defaultValue={''}
                                value={productDescription}
                                onChange={(e) => setProductDescription(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="col-span-6">
                            <label htmlFor="ticket-price" className="block text-sm font-medium leading-6 text-gray-900">
                              Qiimaha Alaabta (Dollar)
                            </label>
                            <div className="mt-1 flex rounded-md shadow-sm">
                              <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 px-3 text-gray-500 sm:text-sm">
                                $
                              </span>
                              <input
                                type="number"
                                name="ticket-price"
                                id="ticket-price"
                                className="block w-full flex-1 rounded-none rounded-r-md border-0 py-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                                placeholder="0.00"
                                aria-describedby="ticket-price-currency"
                                defaultValue={''}
                                value={productPrice}
                                // check if the value is empty, numeric, and has a maximum of 9 digits
                                onChange={(e) => {
                                  const value = e.target.value;
                                  if (value === '' || (/^[0-9]{0,9}$/.test(value))) {
                                    setProductPrice(value);
                                  }
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-span-6 sm:col-span-6">
                            <label htmlFor="phone-address" className="block text-sm font-medium leading-6 text-gray-900">
                              WhatsApp Number <a className='text-red-600' >(Fadlan ka bilaw 65 ama 63)</a>
                            </label>
                            <div className="mt-2 flex rounded-md shadow-sm">
                              <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 px-3 text-gray-500 sm:text-sm">
                                +252
                              </span>
                              <input
                                type="text"
                                name="phone-website"
                                id="phone-website"
                                className="block w-full flex-1 rounded-none rounded-r-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                                placeholder=" 63 4123456"
                                aria-describedby="phone-website-description"
                                onChange={(e) => {
                                  const value = e.target.value;
                                  // Check if the value is empty, numeric, starts with '6', and has a maximum of 9 digits
                                  if (value === '' || (/^[6][0-9]{0,8}$/.test(value))) {
                                    setProductContact(value);
                                  }
                                }}
                                value={productContact}
                              />
                            </div>
                          </div>
                          <div className="sm:col-span-6">
                            <label htmlFor="pictures" className="block text-sm font-medium text-gray-700">
                              Sawirada Alaabta <a className='text-red-600'>(4 sawir kaliya)</a>
                            </label>
                            <>
                              <div class="flex relative flex-col-reverse items-center  justify-center w-full">
                                {productImageUploading && (
                                  <div className="absolute top-0 left-0 right-0 bottom-0 bg-gray-100 bg-opacity-50 flex items-center justify-center">
                                    <svg className="animate-spin h-10 w-10 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none"
                                      viewBox="0 0 24 24">
                                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                    </svg>
                                    

                                  </div>
                                )}

                              {productImages.length < 4 && (
                                <div class="flex flex-row  items-center w-full">
                                  <label for="dropzone-file" class="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 darks:hover:bg-bray-800 darks:bg-gray-700 hover:bg-gray-100 darks:border-gray-600 darks:hover:border-gray-500 darks:hover:bg-gray-600">
                                    <div class="flex flex-col  items-center justify-center pt-5 pb-6">
                                      <svg class="w-8 h-8 mb-4 text-gray-500 darks:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2" />
                                      </svg>
                                      <p class="mb-2 text-sm text-gray-500 darks:text-gray-400"><span class="font-semibold">Soo rar Sawiro </span> </p>
                                      {/* <p class="text-xs text-gray-500 darks:text-gray-400"> 4 sawir kaliya</p> */}
                                     
                                    </div>
                                    
                                    <input id="dropzone-file" 
                                      type="file"
                                      class="hidden" 
                                      accept='image/svg+xml, image/png, image/jpg, image/jpeg, image/gif'
                                      multiple onChange={handleImageUpload} 
                                      />
                                    

                                  </label>
                                </div>
                                )}
                                {
                                  productImages.length > 0 && (
                                    <div className="grid grid-cols-2 gap-4 mt-4 p-3">
                                      {productImages.map((image, index) => (
                                        <div key={index} className="relative">
                                          <Image src={image} alt="Product Image" className="rounded-lg" />
                                          <button
                                            type="button"
                                            className="absolute top-0 right-0 -mt-2 -mr-2 bg-red-500 rounded-full p-1.5"
                                            onClick={() => {
                                              const images = [...productImages];
                                              images.splice(index, 1);
                                              setProductImages(images);
                                            }}
                                          >
                                            <span className="sr-only">Remove</span>
                                            <svg className="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none"
                                              viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                                d="M6 18L18 6M6 6l12 12" />
                                            </svg>
                                          </button>
                                        </div>
                                      ))}
                                    </div>
                                  )
                                }
                              </div>
                            </>

                          </div>
                        </div>
                        {productError && <p className="mt-2 text-sm text-red-500">
                          {productError}
                          
                        </p>
                        }
                      </div>
                    </div>
                    <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                      <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 sm:ml-3 sm:w-auto"
                       onClick={(e) => handleAddProduct(e)}
                      >
                        Samee Alaab Cusub
                      </button>
                      <button
                        type="button"
                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                       onClick={() => setAddProductModal(false)}
                       ref={cancelButtonRef}
                      >
                        Ka Noqo
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>





          {/* Product List */}
          <div className="grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8">
            {loading &&
              <div role="status">
                <svg aria-hidden="true" class="absolute top-1/2 left-1/2 w-16 h-16 text-gray-200 animate-spin  fill-green-500" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                  <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                </svg>
                <span class="sr-only">Loading...</span>
                <div className="absolute top-1/2 mt-20 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                <button 
                className='bg-green-500 text-white px-4 py-2 rounded-lg'
                onClick={() => {
                  deleteCachedProducts();
                  fetchProductsFromFirebase();
                }}>Hadii aanad arkin alaabta, TAABO HALKAN</button>
                </div>
              </div>
            }
            {
            currentProducts?.filter(product => product?.approved || mySubmissions?.includes(product.id))?.map(product => (
              
              <a key={product.id}
                href={`/productDetails/${product.id}`}
                className="group">
                <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-lg bg-gray-200 xl:aspect-h-8 xl:aspect-w-7">
                  <img
                    src={Array.isArray(product.image) ? product.image[0] : product.image}
                    alt={product.imageAlt}
                    className="h-full w-full object-cover object-center group-hover:opacity-75"
                  />
                  {mySubmissions?.includes(product.id) && product?.approved === false && (
                      <Tag style={{ width: '100px', height: '23px', position: 'absolute', top: 'auto', bottom: '10px', left: 'auto', right: '10px' }} color="green" >Sugaya Jawaab </Tag>
                      )}
                </div>
                <div className="flex justify-between items-center">
                  <p className="mt-4 text-sm font-medium text-gray-900">$ {product.price}</p>
                  <div className="flex items-center ">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 mt-4 mr-2 text-gray-500 ">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                      <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                    </svg>

                    <p className="mt-4 text-sm font-medium text-gray-900">{product?.views ? product.views : 0}</p>
                  </div>
                </div>
                <h3 className="mt-1 text-lg text-gray-700 hover:underline font-semibold">
                  {product.name}
                </h3>
              </a>
            ))}
          </div>
          <div className="text-center text-2xl p-4 text-gray-500">
            {loading === false && currentProducts?.length === 0 && (
            <p>
              0 Alaab ah ayaa la helay
            </p>
            )}
          </div>
            

          {loading === false && totalNumberOfProducts > 24 && (
              <div className="flex p-4 justify-center items-center">
                <ConfigProvider
                  theme={{
                    token: {
                      colorPrimary: '#22c55e'
                    }
                  }}  >
                  <Pagination
                    current={currentPage}
                    onChange={(page) => setCurrentPage(page)}
                    total={totalNumberOfProducts}
                    pageSize={itemsPerPage}
                    type="primary"
                    showSizeChanger={false}
                  />
                </ConfigProvider>
              </div>
            )}

        </div>

      </div>

    </div>
  )
}
