import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { db, storage } from "../firebase";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { message, Progress, Alert } from "antd";

const Signup = () => {

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [userPassword, setUserPassword] = useState('');
    const [userConfirmPassword, setUserConfirmPassword] = useState('');
    const [userPhoneNumber, setUserPhoneNumber] = useState('');
    const [userAbout, setUserAbout] = useState('');
    const [userPhoto, setUserPhoto] = useState(null);

    // Collecting device info
    const [deviceInfo, setDeviceInfo] = useState({});
    useEffect(() => {

        const getNetworkType = () => {
            const connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection;
            if (connection) {
                return connection.type || 'Unknown';
            }
            return 'Not available';
        };

        const fetchIP = async () => {
            try {
                const response = await fetch('https://api.ipify.org?format=json');
                const data = await response.json();
                return data.ip;
            } catch (error) {
                console.error('Error fetching IP:', error);
                return 'Unavailable';
            }
        };

        const getDeviceInfo = async () => {
            const ip = await fetchIP();

            return {
                deviceCategory: window.innerWidth <= 768 ? 'Mobile' : 'Desktop', // Simple check for mobile or desktop
                operatingSystem: navigator.platform,
                browserType: navigator.userAgent,
                screenResolution: `${window.screen.width} x ${window.screen.height}`,
                language: navigator.language,
                network: getNetworkType(),
                ipAddress: ip,
            };
        };

        getDeviceInfo().then(info => setDeviceInfo(info));

    }, []);


    const [photoUrl, setPhotoUrl] = useState('');
    const [photoProgress, setPhotoProgress] = useState(0);


    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);


    const navigate = useNavigate();

    const { HandleSignup, highlevelError, HandleGoogleSignup, HandleFacebookSignup, } = useAuth();

    const HandleSignupSubmit = async (e) => {
        e.preventDefault();
        setError('');
    
        if (firstName === '' || lastName === '' || userEmail === '' || userPassword === '' || userConfirmPassword === '' || userPhoneNumber === '' || userAbout === '') {
            return setError('Fadlan buuxi foomka oo dhan');
        } else if (userPassword !== userConfirmPassword) {
            return setError('Furaha sirta ah iyo hubinta furaha sirta ah isma laha');
        }
        try {
            await HandleSignup(
                firstName, 
                lastName, 
                userEmail, 
                userPassword, 
                userPhoneNumber,
                userAbout,
                userConfirmPassword, 
                userPhoto, 
                // [deviceInfo?.deviceCategory, deviceInfo?.operatingSystem, deviceInfo?.browserType, deviceInfo?.screenResolution, deviceInfo?.language, deviceInfo?.network, deviceInfo?.ipAddress],
                navigate, 
                setError, 
                setLoading
                );
            Alert.success('Akoon cusub ayaa laguu sameeyay');
        } catch (error) {
            // setError(highlevelError);
            message.error('Laguma guulaysan inaad samaysato Akoon cusub');
            console.log(error);
        }
    }


    const HandleGoogleSignupSubmit = async (e) => {
        e.preventDefault();
        setError('');
        try {
            await HandleGoogleSignup();
            Alert.success('Akoon cusub ayaa laguu sameeyay');
        } catch (error) {
            setError(highlevelError);
            message.error('Laguma guulaysan inaad samaysato Akoon cusub');
        }
    }



    

    return (
        <div className="max-w-[900px] mx-auto my-[50px]">

            <div className="md:grid  md:gap-6 mx-2 darks:bg-[slate-900] rounded-md p-4">
                <h2 className="text-3xl font-bold text-center text-green-500 p-4 rounded-md">
                    Akoon Cusub Samee
                </h2>

                
                <div className="md:col-span-1">
                    <div className="px-4 sm:px-0">
                    <h3 className="text-lg font-medium leading-6 text-gray-900 darks:text-gray-100">Warbixin ku saabsan Qofkaad tahay</h3>
                        <p className="mt-1 text-sm text-gray-600">
                        Akoon hore ayaan ugu lahaa? {' '}
                            <Link to="/login" className="text-green-500">
                                Gal Akoon kaaga
                            </Link>
                        </p>
                    </div>
                </div>

                {/* <div className="mt-6 grid grid-cols-1 gap-3">

                        <div>
                            <button 
                                onClick={HandleGoogleSignupSubmit}
                                type="button" 
                                className="text-black bg-gray-100 hover:bg-gray-300 focus:ring-4 focus:outline-none focus:ring-[#4285F4]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-[#4285F4]/55 me-2 mb-2 w-full">
                                <svg class="w-4 h-4 me-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 19">
                                    <path fill-rule="evenodd" d="M8.842 18.083a8.8 8.8 0 0 1-8.65-8.948 8.841 8.841 0 0 1 8.8-8.652h.153a8.464 8.464 0 0 1 5.7 2.257l-2.193 2.038A5.27 5.27 0 0 0 9.09 3.4a5.882 5.882 0 0 0-.2 11.76h.124a5.091 5.091 0 0 0 5.248-4.057L14.3 11H9V8h8.34c.066.543.095 1.09.088 1.636-.086 5.053-3.463 8.449-8.4 8.449l-.186-.002Z" clip-rule="evenodd" />
                                </svg>
                                Kusamee Akoonka Google
                            </button>

                        </div>
                        

                </div>
                <div className="mt-6 grid grid-cols-1 gap-3">

                        
                        <div>
                            <button type="button" className="text-white bg-[#4285F4] hover:bg-[#4285F4]/90 focus:ring-4 focus:outline-none focus:ring-[#4285F4]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-[#4285F4]/55 me-2 mb-2 w-full">
                                <svg class="w-4 h-4 me-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 8 19">
                                    <path fill-rule="evenodd" d="M6.135 3H8V0H6.135a4.147 4.147 0 0 0-4.142 4.142V6H0v3h2v9.938h3V9h2.021l.592-3H5V3.591A.6.6 0 0 1 5.592 3h.543Z" clip-rule="evenodd" />
                                </svg>
                                Samee Akoonka Facebook 
                            </button>

                        </div>

                </div> */}
                
                <div className="mt-5 md:col-span-2 md:mt-0">
                    <form onSubmit={(e) => HandleSignupSubmit(e)}>
                        <div className="overflow-hidden shadow sm:rounded-md">
                            <div className="bg-white px-4 py-5 sm:p-6 darks:bg-slate-900">
                                <div className="grid grid-cols-6 gap-6">
                                    <div className="col-span-6 sm:col-span-3">
                                        <label htmlFor="first-name" className="block text-sm font-medium text-gray-700 darks:text-gray-100">
                                            Magacaaga
                                        </label>
                                        <input
                                            type="text"
                                            onChange={(e) => setFirstName(e.target.value)}
                                            name="first-name"
                                            id="first-name"
                                            placeholder='tusaale: Abdirahman'
                                            autoComplete="given-name"
                                            className="mt-1 block w-full rounded-md darks:bg-slate-900 darks:text-gray-50 border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                                        />
                                    </div>

                                    <div className="col-span-6 sm:col-span-3">
                                        <label htmlFor="last-name" className="block text-sm font-medium text-gray-700 darks:text-gray-100">
                                        Magaca Aabo
                                        </label>
                                        <input
                                            type="text"
                                            onChange={(e) => setLastName(e.target.value)}
                                            name="last-name"
                                            id="last-name"
                                            placeholder='tusaale: Yasin Ibrahim'
                                            autoComplete="family-name"
                                            className="mt-1 block w-full rounded-md darks:bg-slate-900 darks:text-gray-50 border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                                            
                                        />
                                    </div>




                                    <div className="col-span-6 sm:col-span-6">
                                        <label htmlFor="email-address" className="block text-sm font-medium text-gray-700 darks:text-gray-100">
                                            Email-kaaga
                                        </label>
                                        <input
                                            type="text"
                                            onChange={(e) => setUserEmail(e.target.value)}
                                            name="email-address"
                                            id="email-address"
                                            autoComplete="email"
                                            placeholder="tusaale: abdirahman@gmail.com"
                                            className="mt-1 block w-full rounded-md darks:bg-slate-900 darks:text-gray-50 border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                                        />
                                    </div>
                                    <div className="col-span-6 sm:col-span-3">
                                        <label htmlFor="last-name" className="block text-sm font-medium text-gray-700 darks:text-gray-100">
                                        Furaha Sirta ah
                                        </label>
                                        <input
                                            type="password"
                                            name="password"
                                            id="password"
                                            placeholder="********"
                                            onChange={(e) => setUserPassword(e.target.value)}
                                            className="mt-1 block w-full rounded-md darks:bg-slate-900 darks:text-gray-50 border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                                        />
                                    </div>
                                    <div className="col-span-6 sm:col-span-3">
                                        <label htmlFor="last-name" className="block text-sm font-medium text-gray-700 darks:text-gray-100">
                                            Hubi Furaha Sirta ah
                                        </label>
                                        <input
                                            type="password"
                                            name="confirm-password"
                                            id="confirm-password"
                                            placeholder="********"
                                            onChange={(e) => setUserConfirmPassword(e.target.value)}
                                            className="mt-1 block w-full rounded-md darks:bg-slate-900 darks:text-gray-50 border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                                        />
                                    </div>

                                    <div className="col-span-6 sm:col-span-6">
                                        <label htmlFor="phone-address" className="block text-sm font-medium leading-6 text-gray-900">
                                            WhatsApp Number <a className="text-red-500">Fadlan kabilaw 63 ama 65</a>
                                        </label>
                                        <div className="mt-2 flex rounded-md shadow-sm">
                                            <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 px-3 text-gray-500 sm:text-sm">
                                                +252 
                                            </span>
                                            <input
                                                type="text"
                                                name="phone-website"
                                                id="phone-website"
                                                className="block w-full flex-1 rounded-none rounded-r-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                                                placeholder="63 4123456"
                                                aria-describedby="phone-website-description"
                                                onChange={(e) => {
                                                    const value = e.target.value;
                                                    // Check if the value is empty, numeric, starts with '6', and has a maximum of 9 digits 
                                                    if (value === '' || (/^[6][0-9]{0,8}$/.test(value))) {
                                                        setUserPhoneNumber(value);
                                                    }}
                                                }
                                                value={userPhoneNumber}


                                            />
                                        </div>
                                    </div>



                                   
                                    <div className="col-span-6">
                                        <label htmlFor="about" className="block text-sm font-medium text-gray-700 darks:text-gray-100">
                                            Xog kugu saabsan
                                        </label>
                                        <div className="mt-1">
                                            <textarea
                                                id="about"
                                                onChange={(e) => setUserAbout(e.target.value)}
                                                name="about"
                                                rows={3}
                                                className="shadow-sm focus:ring-green-500 darks:bg-slate-900 darks:text-gray-50 focus:border-green-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md"
                                                placeholder="tusaale: Waxaan ahay Abdirahman Yasin, waxaan joogaa magaalada Hargeysa, waxaannu iibinaa baabuur tayo leh"
                                                defaultValue={''}
                                            />
                                        </div>
                                    </div>
                                </div>
                                
                                {/* Error Message */}
                                {error &&
                                <div className="flex justify-center mt-5">
                                    <Alert message={error} type="error" showIcon style={{ width: '100%' }} />
                                </div>
                                }
                                <div className="flex justify-cente w-full mt-5">
                                {highlevelError && highlevelError === 'Firebase: Error (auth/email-already-in-use).' && <Alert message='Email-ka aad gelisay waa mid jira, Fadlan taabo Gal Akoon-kaaga' type="error" showIcon />}
                                {highlevelError && highlevelError === 'Firebase: Error (auth/invalid-email).' && <Alert message='Email-ka aad gelisay waa mid aan saxnayn, Fadlan iska hubi' type="error" showIcon />}
                                {highlevelError && highlevelError === 'Firebase: Error (auth/weak-password).' && <Alert message='Furaha sirta ah waa daciif, Fadlan fure adag gali' type="error" showIcon />}
                                </div>
                                {/* Success Message */}
                            </div>
                             
                            <div className="bg-gray-50 darks:bg-slate-900 darks:text-gray-50 px-4 py-3 text-right sm:px-6">
                                <button
                                    type="submit"
                                    onClick={(e) => HandleSignupSubmit(e)}
                                    className="inline-flex w-full justify-center rounded-md border border-transparent bg-green-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                                >
                                    Samee Akoon Cusub
                                </button>

                                

                            </div>

                            

                        </div>

                    </form>

                </div>

            </div>

            <div className="text-center text-gray-500 p-10">
                <p>  Warbixin Qaldan{' '}<Link to="/" className="text-green-500">Ku noqo qaybta Alaabaha</Link></p>

            </div>

        </div>
    )
}

export default Signup