import React from 'react'

function PageNotFound() {
    return (
        <div class="lg:px-24 lg:py-24 md:py-20 md:px-44 px-4 py-24 items-center flex justify-center flex-col-reverse lg:flex-row md:gap-28 gap-16">
            <div class="xl:pt-24 w-full xl:w-1/2 relative pb-12 lg:pb-0">
                <div class="relative">
                    <div class="absolute">
                        <div class="">
                            <h1 class="my-2 text-gray-800 font-bold text-2xl">
                                Bogga aad booqatay maaha mid jira!
                            </h1>
                            <p class="my-2 text-gray-800">Fadlan ku noqo qaybta Alaabaha.</p>
                            <button 
                            class="sm:w-full lg:w-auto my-2 border rounded md py-4 px-8 text-center bg-green-600 text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-700 focus:ring-opacity-50"
                            onClick={() => window.history.back()}
                            >Igu Celi Qaybta Alaabaha</button>
                        </div>
                    </div>
                    
                </div>
            </div>
            <div>
                <h1 
                class="text-9xl font-bold text-green-600"
                >404</h1>
            </div>
        </div>
    )
}

export default PageNotFound