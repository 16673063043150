import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  updateDoc,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import { db } from '../firebase'; // Import your firebase configuration
import { Link } from 'react-router-dom';
import { Image, Carousel, message, Spin } from 'antd';
import { useAuth } from '../context/AuthContext.js';

const Profile = () => {
  const { id } = useParams();
  const { user } = useAuth();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const [fname, setFName] = useState('');
  const [lname, setLName] = useState('');
  const [phone, setPhone] = useState('');
  const [userAbout, setUserAbout] = useState('');
  const [photoUrl, setPhotoUrl] = useState('');


  // fetch user data
  useEffect(() => {
    // Check if user.uid is defined
    if (user && user.uid) {
      const q = query(collection(db, "users"), where("uid", "==", user.uid));
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        querySnapshot.forEach((doc) => {
          setFName(doc.data().firstName);
          setLName(doc.data().lastName);
          setPhone(doc.data().number);
          setUserAbout(doc.data().about);
          // setUserCoverPhoto(doc.data().photoUrl);
        });
      });
  
      // Return the unsubscribe function to clean up the listener
      return unsubscribe;
    } else {
      console.log("user.uid is undefined");
      // Optionally handle the undefined case here
      return () => {};
    }
  }, [user.uid]);
  

  // update user data
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    try {
      const userRef = doc(db, "users", user.uid);
      await updateDoc(userRef, {
        firstName: fname,
        lastName: lname,
        number: phone,
        about: userAbout,
        // photoUrl: photoUrl,
      });
      setLoading(false);
      message.success('Akoon-kaaga waad cusbooneysiisay');
    } catch (error) {
      setError('Laguma guulaysan in aad Akoonkaaga cusbooneysiiso');
      setLoading(false);
    }
  };
  return (
    <div className="bg-white">
      <div className="max-w-7xl  mx-auto py-16 px-4 sm:px-6 lg:px-8">
        <Link to="/">
        <button 
        className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
          Ku Noqo Alaabaha
        </button>
        </Link>
      </div>
    <div className="md:mt-10 sm:mt-0 max-w-[900px] min-h-[90vh] mx-auto my-16 ">

      <div className="grid grid-cols-1 sm:grid-cols-2 bg-white darks:bg-gray-800 rounded-md shadow-xl">
        <div className="col-span-2 sm:col-span-1 ">
          <div className="flex flex-row items-start justify-start p-4">
            <div className="w-full">
              <img 
              src='https://cdn3.iconfinder.com/data/icons/basic-ui-element-s94-3/64/Basic_UI_Icon_Pack_-_Glyph_user-512.png'
              alt="cover" className="object-cover rounded-md" />

              {/* <input className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded w-full" type='file' 
              onChange={(e) => setPhotoUrl(e.target.files[0])}
                /> */}
              {/* <process value={photoProgress} max="100" /> */}
            </div>
          </div>
        </div>
        <div className="col-span-2 sm:col-span-1 ">
          <div className="flex flex-col items-start justify-between p-4 h-full ">
            <div className="flex flex-col w-full  p-4">
            <div className="flex flex-col my-5 items-start justify-center">
                <label htmlFor="name" className="text-gray-700 darks:text-gray-200">Email-kaaga Address <span className="text-red-500">Email-ka lama badali karo </span></label>
                <h2 className="border-2 border-green-300 w-full p-2 rounded-md focus:outline-none focus:border-green-500 darks:focus:border-green-500 darks:bg-gray-700 darks:text-gray-300">
                  {user.email}
                  </h2>
              </div>
              <div className="flex flex-col my-5 items-start justify-center">
                <label htmlFor="fname" className="text-gray-700 darks:text-gray-200">Magacaaga</label>
                <input type="text" 
                value={fname} onChange={(e) => setFName(e.target.value)} 
                className="border-2 border-green-300 w-full p-2 rounded-md focus:outline-none focus:border-green-500 darks:focus:border-green-500 darks:bg-gray-700 darks:text-gray-300" />
              </div>
              <div className="flex flex-col my-5 items-start justify-center">
                <label htmlFor="lname" className="text-gray-700 darks:text-gray-200">Magaca Aabo iyo Awoowo</label>
                <input type="text" 
                value={lname} onChange={(e) => setLName(e.target.value)} 
                className="border-2 border-green-300 w-full p-2 rounded-md focus:outline-none focus:border-green-500 darks:focus:border-green-500 darks:bg-gray-700 darks:text-gray-300" />
              </div>
              <div className="flex flex-col my-5 items-start justify-center">
              <label htmlFor="name" className="text-gray-700 darks:text-gray-200">
                Lambarkaaga 
                <span className="text-red-500"> +252 (Fadlan 63/65 ka bilaw)</span>
              </label>
              <input type="text" 
                placeholder='63 1234567'
                value={phone} 
                onChange={(e) => {
                  const value = e.target.value;
                  // Check if the value is empty, numeric, starts with '6', and has a maximum of 9 digits
                  if (value === '' || (/^[6][0-9]{0,8}$/.test(value))) {
                    setPhone(value);
                  }
                }} 
                className="border-2 border-green-300 w-full p-2 rounded-md focus:outline-none focus:border-green-500 darks:focus:border-green-500 darks:bg-gray-700 darks:text-gray-300" />
            </div>

              <div className="flex flex-col my-5 items-start justify-center">
                <label htmlFor="about" className="text-gray-700 darks:text-gray-200">Xog kugu saabsan</label>
                <textarea type="text" 
                value={userAbout} onChange={(e) => setUserAbout(e.target.value)} 
                className="border-2 border-green-300 w-full p-2 rounded-md focus:outline-none focus:border-green-500 darks:focus:border-green-500 darks:bg-gray-700 darks:text-gray-300 h-24" />
              </div>
              
              
              
              {error && message('error', error)}
              {/* {success && message('success', success)} */}
            </div>
            <button 
            onClick={handleSubmit} 
            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded w-full">
              Cusboonaysii Akoon-kaaga
            </button>
          </div>
        </div>

      </div>
    </div>
    </div>
    
  )
}

export default Profile