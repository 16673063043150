import logo from './logo.svg';
import './App.css';
import { Route, Routes } from 'react-router-dom';

// pages
import Hero from './LandingPage/Hero';
import ProductDetail from './pages/ProductDetails';
import MyListings from './pages/MyListings';
import PageNotFound from './pages/PageNotFound';
import Profile from './pages/Profile';
import Moderators from './pages/Moderators';
import DataCollection from './pages/DataCollection';
import PrisonersDilemmaGame from './pages/PrisonersDilemmaGame';

// context
import { AuthContextProvider } from './context/AuthContext';

// auth pages
import Login from './auth/Login';
import Signup from './auth/Signup';

// layout
import Header from './layout/Header';


function App() {
  return (
    <div className="bg-white w-full h-screen">
      
      {/* <p className="flex h-10 items-center justify-center bg-green-500 px-4 text-sm font-medium text-white sm:px-6 lg:px-8">
        Qaybta Application-ka way soo socotaa 
        </p> */}
      

    <AuthContextProvider>
    <Routes>
      <Route path="/" element={<Hero/>}/>
      <Route path="/productDetails/:id" element={<ProductDetail/>}/>
      <Route path="/login" element={<Login/>}/>
      <Route path="/signup" element={<Signup/>}/>
      <Route path="/myListings" element={<MyListings/>}/>
      <Route path="*" element={<PageNotFound/>}/>
      <Route path="/profile" element={<Profile/>}/>
      <Route path="/moderators" element={<Moderators/>}/>
      {/* <Route path="/prisonersDilemmaGame" element={<PrisonersDilemmaGame/>}/>

      <Route path="/dataCollection" element={<DataCollection/>}/> */}
      {/* <Route path="/" element={<Header/>}>
        
      </Route> */}
      
    </Routes>
    </AuthContextProvider>
    </div>
  );
}

export default App;
