import React, { useEffect, useState } from 'react'
import {
    query, collection, where, getDoc, setDoc, onSnapshot, updateDoc, doc, deleteDoc, serverTimestamp, addDoc, increment
} from 'firebase/firestore';
import { db } from '../firebase';
import { useAuth } from '../context/AuthContext';
import { Link, useNavigate } from 'react-router-dom'
import { Image, Carousel, message, Spin } from 'antd';



const Moderators = () => {

    const [waitingListProducts, setWaitingListProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [oldProducts, setOldProducts] = useState([]);

    const { user, HandleLogout } = useAuth();
    const navigate = useNavigate();

    // Check if user is a moderator
    const checkUser = () => {
        if (user && user?.uid === '0lsiJKDkAOPQQ3LVP1I2LMDNqmy2' || user?.uid === '84gW7GXVKfPL5XdG9vot1qzQkeB3') {
            return true;
        } else {
            return false;
        }
    }

    useEffect(() => {
        const q = query(collection(db, "products"));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const productsArray = [];
            querySnapshot.forEach((doc) => {
                const product = doc.data();
                product.id = doc.id; // Add the document ID to the product object
                productsArray.push(product);
            });
            setWaitingListProducts(productsArray.filter((product) => product.approved === false));
            setOldProducts(productsArray.filter((product) => product.approved === true));
            setLoading(false);
        });
        return unsubscribe;
    }, [checkUser === true]);



    const BanUser = async (e) => {

        try {
            await updateDoc(doc(db, "users", user.uid), {
                banned: true,
                bannedBy: user.uid
            });
            message.success('User has been banned');
        } catch (error) {
            message.error('Error banning user');
        }
    }
      
    

    const ApproveProduct = async (productId) => {
        try {
            const docRef = doc(db, "products", productId);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                const product = docSnap.data();
                if (product.approved === false) {
                    await updateDoc(doc(db, "products", productId), {
                        approved: true,
                        approvedBy: user.uid,
                        owner: product.owner === null ? user.uid : product.owner
                    });
                    message.success('Product has been approved');
                } else {
                    message.error('Product is already approved');
                }
            } await logUpdate();
        }

        catch (error) {
            message.error('Error approving product');
        }
    }

    // update notificaiton document
    const logUpdate = async () => {
        try {
            const docRef = doc(db, "analytics", "readCalls");
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                await updateDoc(doc(db, "analytics", "readCalls"), {
                    latestUpdate: serverTimestamp(),
                    latestUpdateBy: user.uid,
                    count: increment(1)
                });
            } else {
                await setDoc(doc(db, "analytics", "readCalls"), {
                    latestUpdate: serverTimestamp(),
                    latestUpdateBy: user.uid,
                    count: increment(1)
                });
            }
        } catch (error) {
            console.error("Error logging update: ", error);
        }
    };

    

    const RemoveProduct = async (productId) => {
        const docRef = doc(db, "products", productId);
        const docSnap = await getDoc(docRef);
        await deleteDoc(docRef);
        if (docSnap.exists()) {
            message.success('Product has been removed');
            logUpdate();
        } else {
            message.error('Product does not exist');
        }
    }










    return (
        <div className="bg-white">
            {loading ? (
                <div role="status">
                    <svg aria-hidden="true" class="absolute top-1/2 left-1/2 w-16 h-16 text-gray-200 animate-spin  fill-green-500" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                    </svg>
                    <span class="sr-only">Loading...</span>
                </div>
            ) : (

                <>

                <div className='max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 bg-white'>

                    {checkUser() ? (
                        <div className='flex p-4 flex-col items-start justify-center'>

                            <div className="mb-4">
                                <Link to="/">
                                    <button className="bg-green-500 hover:bg-green-700 text-white py-2 px-4 rounded">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 inline-block" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                                d="M10 19l-7-7m0 0l7-7m-7 7h18" />
                                        </svg>
                                        <a className="inline-block  ml-2">Ku noqo Alaabaha</a>

                                    </button>
                                </Link>
                            </div>

                            <h1 className='text-2xl text-black'>{waitingListProducts.length} Products waiting for approval</h1>


                            <div className="overflow-x-scroll overflow-y-hidden bg-white rounded-lg shadow-md w-full ">
                                <div className="flex flex-col gap-2 items-center justify-start w-full  h-full ">

                                    <div class="flex flex-col w-full">
                                        <table className="w-full divide-y divide-gray-200 darks:divide-gray-700">
                                            <thead className="bg-gray-50 darks:bg-gray-800">
                                                <tr>
                                                    <th
                                                        scope="col"
                                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                    >
                                                        Product Description
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                    >
                                                        Images
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                    >
                                                        Date Added
                                                    </th>
                                                    {/* <th
                                                    scope="col"
                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                >
                                                    Assigned To
                                                </th> */}

                                                    <th
                                                        scope="col"
                                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                    >
                                                        Actions
                                                    </th>

                                                </tr>
                                            </thead>
                                            <tbody className="bg-white divide-y divide-gray-200 darks:divide-gray-700 darks:bg-gray-800">

                                                {waitingListProducts.map((product) => (
                                                    <tr key={product.id} className=''>
                                                        <td className="px-6 py-4  flex flex-wrap whitespace-wrap">
                                                            <div className="flex flex-row items-start justify-start">
                                                                {/* <div className="flex-shrink-0 h-10 w-10">
                                                                    <img className="h-10 w-10 rounded-full" src={product.image[0]} alt="" />
                                                                </div> */}
                                                                <div className="ml-4">
                                                                    <div className="text-sm font-medium text-gray-900">Product Name: {product.name}</div>
                                                                    <div className="text-sm text-gray-500 overflow-auto">Product Description: {product.description}
                                                                    </div>
                                                                    <div className="text-sm text-gray-500">Product Price: {product.price}</div>
                                                                    <div className="text-sm text-gray-500">Product Category: {product.category}</div>
                                                                    <div className="text-sm text-gray-500">Product Location: {product.location}</div>
                                                                    <div className="text-sm text-gray-500">Product ID : {product.id}</div>
                                                                    <div className="text-sm text-gray-500">Product Owner : {product.owner}</div>

                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="max-w-[290px] whitespace-nowrap">

                                                            <div className="flex flex-rowitems-start justify-start">
                                                                <Image.PreviewGroup>
                                                                    {product.image?.map((image) => (
                                                                        <Image
                                                                            width={100}
                                                                            height={100}
                                                                            style={{ objectFit: 'cover' }}
                                                                            src={image}

                                                                        />
                                                                    ))}
                                                                </Image.PreviewGroup>
                                                            </div>

                                                        </td>
                                                        <td className="px-6 py-4 whitespace-nowrap">
                                                            <div className="text-sm text-gray-900">{new Date(product.updatedAt?.seconds * 1000).toLocaleDateString('en-US')}</div>
                                                        </td>
                                                        {/* <td className="px-6 py-4 whitespace-nowrap">
                                                        <div className="text-sm text-gray-900">Jane Cooper</div>
                                                        <div className="text-sm text-gray-500">Regional Paradigm Technician</div>
                                                    </td> */}
                                                        <td className="  px-6 py-4 whitespace-nowrap">
                                                            <div className="flex flex-row items-center justify-between gap-2">
                                                                {product?.approved === false && (
                                                                    <>
                                                                    <button
                                                                        onClick={
                                                                            (e) => {
                                                                                window.confirm('Are you sure you want to approve this product?') && ApproveProduct(product.id);
                                                                            }
                                                                        }
                                                                        className='bg-green-500 text-white px-4 py-2 rounded-md'>Approve</button>
                                                                        <button
                                                                        onClick={
                                                                            (e) => {
                                                                                window.confirm('Are you sure you want to remove this product?') && RemoveProduct(product.id);
                                                                            }
                                                                        }
                                                                        className='bg-orange-500 text-white px-4 py-2 rounded-md'>Remove</button>
                                                                        
                                                              
                                                                <button
                                                                    onClick={
                                                                        (e) => {
                                                                            window.confirm('Are you sure you want to shadow ban this user?') && BanUser();
                                                                        }
                                                                    }
                                                                    className='bg-red-500 text-white px-4 py-2 rounded-md shadow-md'>
                                                                    Shadow Ban
                                                                </button>
                                                                </>
                                                                  ) }

                                                            </div>

                                                        </td>
                                                    </tr>
                                                ))}

                                            </tbody>
                                        </table>
                                    </div>




                                </div>
                            </div>

                            <h1 className='text-2xl text-black'>{oldProducts.length} Products already approved</h1>
                            <div className="overflow-x-scroll overflow-y-hidden bg-white rounded-lg shadow-md w-full ">
                                <div className="flex flex-col gap-2 items-center justify-start w-full  h-full ">

                                    <div class="flex flex-col w-full">
                                        <table className="w-full divide-y divide-gray-200 darks:divide-gray-700">
                                            <thead className="bg-gray-50 darks:bg-gray-800">
                                                <tr>
                                                    <th
                                                        scope="col"
                                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                    >
                                                        Product Description
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                    >
                                                        Views
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                    >
                                                        Images
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                    >
                                                        Date Added
                                                    </th>
                                                    {/* <th
                                                    scope="col"
                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                >
                                                    Assigned To
                                                </th> */}

                                                    <th
                                                        scope="col"
                                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                    >
                                                        Actions
                                                    </th>

                                                </tr>
                                            </thead>
                                            <tbody className="bg-white divide-y divide-gray-200 darks:divide-gray-700 darks:bg-gray-800">

                                                {oldProducts?.map((product) => (
                                                    <tr key={product.id} className=''>
                                                        <td className="px-6 py-4  flex flex-wrap whitespace-wrap">
                                                            <div className="flex flex-row items-start justify-start">
                                                                {/* <div className="flex-shrink-0 h-10 w-10">
                                                                    <img className="h-10 w-10 rounded-full" src={product.image[0]} alt="" />
                                                                </div> */}
                                                                <div className="ml-4">
                                                                    <div className="text-sm font-medium text-gray-900">Product Name: {product.name}</div>
                                                                    <div className="text-sm text-gray-500 overflow-auto">Product Description: {product.description}
                                                                    </div>
                                                                    <div className="text-sm text-gray-500">Product Price: {product.price}</div>
                                                                    <div className="text-sm text-gray-500">Product Category: {product.category}</div>
                                                                    <div className="text-sm text-gray-500">Product Location: {product.location}</div>
                                                                    <div className="text-sm text-gray-500">Product ID : {product.id}</div>
                                                                    <div className="text-sm text-gray-500">Product Owner : {product.owner}</div>

                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="px-6 py-4 whitespace-nowrap">
                                                            <div className="text-sm text-gray-900">{product.views}</div>
                                                        </td>

                                                        <td className="max-w-[290px] whitespace-nowrap">

                                                            <div className="flex flex-rowitems-start justify-start">
                                                                <Image.PreviewGroup>
                                                                    {product.image?.map((image) => (
                                                                        <Image
                                                                            width={100}
                                                                            height={100}
                                                                            style={{ objectFit: 'cover' }}
                                                                            src={image}

                                                                        />
                                                                    ))}
                                                                </Image.PreviewGroup>
                                                            </div>

                                                        </td>
                                                        <td className="px-6 py-4 whitespace-nowrap">
                                                            <div className="text-sm text-gray-900">{new Date(product.updatedAt?.seconds * 1000).toLocaleDateString('en-US')}</div>
                                                        </td>
                                                        {/* <td className="px-6 py-4 whitespace-nowrap">
                                                        <div className="text-sm text-gray-900">Jane Cooper</div>
                                                        <div className="text-sm text-gray-500">Regional Paradigm Technician</div>
                                                    </td> */}
                                                        <td className="  px-6 py-4 whitespace-nowrap">
                                                            <div className="flex flex-row items-center justify-between gap-2">
                                                                {product?.approved === true && (
                                                                    <>
                                                                    
                                                                        <button
                                                                        onClick={
                                                                            (e) => {
                                                                                window.confirm('Are you sure you want to remove this product?') && RemoveProduct(product.id);
                                                                            }
                                                                        }
                                                                        className='bg-orange-500 text-white px-4 py-2 rounded-md'>Remove</button>
                                                                        
                                                                </>
                                                                  ) }

                                                            </div>

                                                        </td>
                                                    </tr>
                                                ))}

                                            </tbody>
                                        </table>
                                    </div>




                                </div>
                            </div>


                            {/* <button onClick={HandleLogout} className='bg-white text-red-500 px-4 py-2 rounded-md'>Logout</button> */}
                        </div>
                    ) : (
                        <div className='flex p-10 flex-col items-center justify-center'>
                            <h1 className='text-2xl text-black'>You are not a moderator</h1>
                            <p className='text-gray-500'>You should not be here</p>
                            <button
                                onClick={() => navigate('/')}
                                className='bg-green-500 mt-3 text-white px-4 py-2 rounded-md'>Go Home</button>
                        </div>

                    )}


                </div>
                

                </> 
            )}
        </div>
    )
}

export default Moderators